import React from 'react';
import { graphql } from 'gatsby';
import { Layout } from '../components/Layout';
import TermsBlock from '../components/TermsPage/TermsBlock';
import TermsStateAddenda from '../components/TermsPage/TermsStateAddenda';
import { SEO } from '../components/SEO';
import { SanityTerms, SanityStateSpecificTerms } from '../../graphql-types';
import { NavbarType } from 'src/components/Layout/nav-switcher';
import { SolidDarkTheme, ThemeProvider } from '@parsleyhealth/cilantro-ui';

interface TermsPageTemplateProps {
  location: {
    pathname: string;
  };
  data: {
    sanityTerms: SanityTerms;
    allSanityStateSpecificTerms: {
      edges: Array<{ node: SanityStateSpecificTerms }>;
    };
  };
}

type HeaderTypes = 'default' | 'insights';

function getTermsNavbar(header: HeaderTypes = 'default'): NavbarType {
  if (header === 'insights') {
    return 'INSIGHTS';
  }
  return 'MAIN_SITE';
}

export default function TermsPageTemplate({
  data: { sanityTerms, allSanityStateSpecificTerms },
  location: { pathname }
}: TermsPageTemplateProps) {
  const showAddenda =
    pathname.includes('clinical-membership-terms') &&
    allSanityStateSpecificTerms &&
    allSanityStateSpecificTerms.edges &&
    allSanityStateSpecificTerms.edges.length > 0;
  const states = allSanityStateSpecificTerms.edges
    .map(i => i.node)
    .filter(i => i.published);
  const header = (sanityTerms.header as HeaderTypes) || 'default';
  return (
    <ThemeProvider>
      <Layout themeNav={SolidDarkTheme} navbarType={getTermsNavbar(header)}>
        <SEO pageTitle={sanityTerms.title} />
        <TermsBlock {...sanityTerms} />
        {showAddenda && states.length > 0 && (
          <TermsStateAddenda states={states} />
        )}
      </Layout>
    </ThemeProvider>
  );
}

export const query = graphql`
  query TermsPageTemplateQuery($id: String!) {
    sanityTerms(id: { eq: $id }) {
      _rawBody
      header
      id
      revisedAt(formatString: "MMMM DD, YYYY")
      showUpdatedAt
      title
    }
    allSanityStateSpecificTerms {
      edges {
        node {
          published
          slug {
            current
          }
          state
        }
      }
    }
  }
`;
