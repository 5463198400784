import React from 'react';
import { SanityStateSpecificTerms, SanityTerms } from '../../../graphql-types';
import StaticPageContainer from '../StaticPages/StaticPageContainer';
import BlockContent from '../BlockContent';
import { H1, H2, Pb } from '@parsleyhealth/cilantro-ui';

interface TermsBlockProps extends SanityTerms, SanityStateSpecificTerms {
  firstInPage?: boolean;
}

export default function TermsBlock({
  _rawBody,
  firstInPage,
  revisedAt,
  showUpdatedAt,
  title
}: TermsBlockProps) {
  let titleElement;
  if (title && firstInPage === false) {
    titleElement = (
      <H2 my="l" textAlign="center">
        {title}
      </H2>
    );
  } else if (title) {
    titleElement = (
      <H1 my="l" textAlign="center">
        {title}
      </H1>
    );
  }
  return (
    <StaticPageContainer>
      {titleElement}
      {_rawBody && <BlockContent blocks={_rawBody} />}
      {revisedAt && showUpdatedAt && (
        <div>
          <Pb>Last Updated: {revisedAt}</Pb>
        </div>
      )}
    </StaticPageContainer>
  );
}
