import React from 'react';
import styled from '@emotion/styled';
import { theme } from '../../styles';

const Wrapper = styled.div`
  max-width: 800px;
  margin: 30px auto;
  color: rgb(79, 79, 79);
  line-height: 1.6;
  font-weight: 200;
  font-size: 16px;
  padding: 120px 20px 60px 20px;

  & > div p {
    margin: 2em 0;
  }

  & > div a {
    text-decoration: none;
    color: #4f4f4f;
    border-bottom: 1px solid rgba(79, 79, 79, 0.5);
    transition: color 0.15s linear, border 0.15s linear;

    &:hover {
      color: ${theme.colors.greenDark};
      border-color: ${theme.colors.greenDark};
    }
  }

  strong {
    font-weight: 400;
  }

  ul {
    margin: 0 0 20px 40px;
  }

  @media screen and (min-width: ${theme.breakpoints[2]}) {
    font-size: 18px;
    color: ${theme.colors.text};
  }
`;

export default ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};
